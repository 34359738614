exports.components = {
  "component---src-components-exclusive-distributor-template-js": () => import("./../../../src/components/ExclusiveDistributorTemplate.js" /* webpackChunkName: "component---src-components-exclusive-distributor-template-js" */),
  "component---src-components-matter-product-template-js": () => import("./../../../src/components/MatterProductTemplate.js" /* webpackChunkName: "component---src-components-matter-product-template-js" */),
  "component---src-pages-1-home-app-js": () => import("./../../../src/pages/1home-app.js" /* webpackChunkName: "component---src-pages-1-home-app-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-service-provider-js": () => import("./../../../src/pages/become-service-provider.js" /* webpackChunkName: "component---src-pages-become-service-provider-js" */),
  "component---src-pages-blog-top-10-knx-visualisation-servers-ultimate-list-js": () => import("./../../../src/pages/blog/top-10-knx-visualisation-servers-ultimate-list.js" /* webpackChunkName: "component---src-pages-blog-top-10-knx-visualisation-servers-ultimate-list-js" */),
  "component---src-pages-compare-1-home-products-js": () => import("./../../../src/pages/compare-1home-products.js" /* webpackChunkName: "component---src-pages-compare-1-home-products-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-free-server-js": () => import("./../../../src/pages/free-server.js" /* webpackChunkName: "component---src-pages-free-server-js" */),
  "component---src-pages-get-1-home-in-your-country-js": () => import("./../../../src/pages/get-1home-in-your-country.js" /* webpackChunkName: "component---src-pages-get-1-home-in-your-country-js" */),
  "component---src-pages-hems-station-js": () => import("./../../../src/pages/hems-station.js" /* webpackChunkName: "component---src-pages-hems-station-js" */),
  "component---src-pages-how-it-works-alexa-knx-js": () => import("./../../../src/pages/how-it-works/alexa-knx.js" /* webpackChunkName: "component---src-pages-how-it-works-alexa-knx-js" */),
  "component---src-pages-how-it-works-alexa-loxone-js": () => import("./../../../src/pages/how-it-works/alexa-loxone.js" /* webpackChunkName: "component---src-pages-how-it-works-alexa-loxone-js" */),
  "component---src-pages-how-it-works-apple-knx-js": () => import("./../../../src/pages/how-it-works/apple-knx.js" /* webpackChunkName: "component---src-pages-how-it-works-apple-knx-js" */),
  "component---src-pages-how-it-works-google-knx-js": () => import("./../../../src/pages/how-it-works/google-knx.js" /* webpackChunkName: "component---src-pages-how-it-works-google-knx-js" */),
  "component---src-pages-how-it-works-google-loxone-js": () => import("./../../../src/pages/how-it-works/google-loxone.js" /* webpackChunkName: "component---src-pages-how-it-works-google-loxone-js" */),
  "component---src-pages-how-it-works-knx-matter-js": () => import("./../../../src/pages/how-it-works/knx-matter.js" /* webpackChunkName: "component---src-pages-how-it-works-knx-matter-js" */),
  "component---src-pages-how-it-works-loxone-matter-js": () => import("./../../../src/pages/how-it-works/loxone-matter.js" /* webpackChunkName: "component---src-pages-how-it-works-loxone-matter-js" */),
  "component---src-pages-how-it-works-matter-knx-js": () => import("./../../../src/pages/how-it-works/matter-knx.js" /* webpackChunkName: "component---src-pages-how-it-works-matter-knx-js" */),
  "component---src-pages-how-it-works-matter-loxone-js": () => import("./../../../src/pages/how-it-works/matter-loxone.js" /* webpackChunkName: "component---src-pages-how-it-works-matter-loxone-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knx-automations-js": () => import("./../../../src/pages/knx-automations.js" /* webpackChunkName: "component---src-pages-knx-automations-js" */),
  "component---src-pages-knx-best-devices-signup-js": () => import("./../../../src/pages/knx-best-devices-signup.js" /* webpackChunkName: "component---src-pages-knx-best-devices-signup-js" */),
  "component---src-pages-knx-homeowners-js": () => import("./../../../src/pages/knx-homeowners.js" /* webpackChunkName: "component---src-pages-knx-homeowners-js" */),
  "component---src-pages-knx-integrators-js": () => import("./../../../src/pages/knx-integrators.js" /* webpackChunkName: "component---src-pages-knx-integrators-js" */),
  "component---src-pages-knx-smart-home-js": () => import("./../../../src/pages/knx-smart-home.js" /* webpackChunkName: "component---src-pages-knx-smart-home-js" */),
  "component---src-pages-knx-sonos-js": () => import("./../../../src/pages/knx-sonos.js" /* webpackChunkName: "component---src-pages-knx-sonos-js" */),
  "component---src-pages-knx-supported-devices-js": () => import("./../../../src/pages/knx-supported-devices.js" /* webpackChunkName: "component---src-pages-knx-supported-devices-js" */),
  "component---src-pages-legal-impressum-js": () => import("./../../../src/pages/legal/impressum.js" /* webpackChunkName: "component---src-pages-legal-impressum-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-loxone-supported-devices-js": () => import("./../../../src/pages/loxone-supported-devices.js" /* webpackChunkName: "component---src-pages-loxone-supported-devices-js" */),
  "component---src-pages-loxone-voice-control-js": () => import("./../../../src/pages/loxone-voice-control.js" /* webpackChunkName: "component---src-pages-loxone-voice-control-js" */),
  "component---src-pages-matter-directory-js": () => import("./../../../src/pages/matter-directory.js" /* webpackChunkName: "component---src-pages-matter-directory-js" */),
  "component---src-pages-matter-pro-js": () => import("./../../../src/pages/matter-pro.js" /* webpackChunkName: "component---src-pages-matter-pro-js" */),
  "component---src-pages-partner-shop-signup-js": () => import("./../../../src/pages/partner-shop-signup.js" /* webpackChunkName: "component---src-pages-partner-shop-signup-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-professionals-knx-js": () => import("./../../../src/pages/products-professionals-knx.js" /* webpackChunkName: "component---src-pages-products-professionals-knx-js" */),
  "component---src-pages-products-professionals-loxone-js": () => import("./../../../src/pages/products-professionals-loxone.js" /* webpackChunkName: "component---src-pages-products-professionals-loxone-js" */),
  "component---src-pages-secure-remote-access-js": () => import("./../../../src/pages/secure-remote-access.js" /* webpackChunkName: "component---src-pages-secure-remote-access-js" */),
  "component---src-pages-server-ch-js": () => import("./../../../src/pages/server-ch.js" /* webpackChunkName: "component---src-pages-server-ch-js" */),
  "component---src-pages-server-for-knx-js": () => import("./../../../src/pages/server-for-knx.js" /* webpackChunkName: "component---src-pages-server-for-knx-js" */),
  "component---src-pages-server-for-loxone-js": () => import("./../../../src/pages/server-for-loxone.js" /* webpackChunkName: "component---src-pages-server-for-loxone-js" */),
  "component---src-pages-shop-for-partners-js": () => import("./../../../src/pages/shop-for-partners.js" /* webpackChunkName: "component---src-pages-shop-for-partners-js" */),
  "component---src-pages-thank-you-for-becoming-service-provider-js": () => import("./../../../src/pages/thank-you-for-becoming-service-provider.js" /* webpackChunkName: "component---src-pages-thank-you-for-becoming-service-provider-js" */),
  "component---src-pages-thank-you-for-signing-up-knx-devices-js": () => import("./../../../src/pages/thank-you-for-signing-up-knx-devices.js" /* webpackChunkName: "component---src-pages-thank-you-for-signing-up-knx-devices-js" */),
  "component---src-pages-thank-you-for-signing-up-server-js": () => import("./../../../src/pages/thank-you-for-signing-up-server.js" /* webpackChunkName: "component---src-pages-thank-you-for-signing-up-server-js" */),
  "component---src-pages-thank-you-for-your-order-js": () => import("./../../../src/pages/thank-you-for-your-order.js" /* webpackChunkName: "component---src-pages-thank-you-for-your-order-js" */)
}

