export const ExclusiveCountryCode = {
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  EG: 'EG',
  GR: 'GR',
  // HU: 'HU',
  IL: 'IL',
  IN: 'IN',
  NO: 'NO',
  NZ: 'NZ',
  PL: 'PL',
  PS: 'PS',
  // TR: 'TR',
  UA: 'UA',
  US: 'US',
}

export const TranslatedCountryCode = {
  DE: 'DE',
  ES: 'ES',
  IT: 'IT',
}

export const exclusive = {
  [ExclusiveCountryCode.CY]: {
    name: 'Cyprus',
    flag: '🇨🇾',
    language: 'Ελληνικά',
    link: '/exclusive-distributor-cyprus',
    title: 'Visit our official Cyprus 🇨🇾 website',
    alt: 'Αποκλειστικός διανομέας 1Home στην Κύπρο',
    btn_redirect: 'Visit 1Home for Cyprus',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.CZ]: {
    name: 'Czechia',
    flag: '🇨🇿',
    language: 'Čeština',
    link: '/exclusive-distributor-czechia',
    title: 'Visit our official Czechia 🇨🇿 website',
    alt: 'Exclusive 1Home distributor in Czechia',
    btn_redirect: 'Visit 1Home for Czechia',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.DK]: {
    name: 'Denmark',
    flag: '🇩🇰',
    language: 'Dansk',
    link: '/exclusive-distributor-denmark',
    title: 'Visit our official Denmark 🇩🇰 website',
    alt: 'Exclusive 1Home distributor in Denmark',
    btn_redirect: 'Visit 1Home for Denmark',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.EG]: {
    name: 'Egypt',
    flag: '🇪🇬',
    language: 'English',
    link: '/exclusive-distributor-egypt',
    title: 'Visit our official Egypt 🇪🇬 website',
    alt: 'Exclusive 1Home distributor in Egypt',
    btn_redirect: 'Visit 1Home for Egypt',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.GR]: {
    name: 'Greece',
    flag: '🇬🇷',
    language: 'Ελληνικά',
    link: '/exclusive-distributor-greece',
    title: 'Visit our official Greece 🇬🇷 website',
    alt: 'Αποκλειστικός διανομέας 1Home στην Ελλάδα',
    btn_redirect: 'Visit 1Home for Greece',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  // [ExclusiveCountryCode.HU]: {
  //   name: 'Hungary',
  //   flag: '🇭🇺',
  //   language: 'Magyar',
  //   link: '/exclusive-distributor-hungary',
  //   title: 'Visit our official Hungary 🇭🇺 website',
  //   alt: 'Exkluzív 1Home forgalmazó Magyarországon',
  //   btn_redirect: 'Visit 1Home for Hungary',
  //   btn_close: 'Continue to the global site & get redirected on checkout',
  // },
  [ExclusiveCountryCode.IL]: {
    name: 'Israel',
    flag: '🇮🇱',
    language: 'English',
    link: '/exclusive-distributor-israel',
    title: 'Visit our official Israel 🇮🇱 website',
    alt: 'Exclusive 1Home distributor in Israel',
    btn_redirect: 'Visit 1Home for Israel',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.IN]: {
    name: 'India',
    flag: '🇮🇳',
    language: 'English',
    link: '/exclusive-distributor-india',
    title: 'Visit our official India 🇮🇳 website',
    alt: 'Exclusive 1Home distributor in India',
    btn_redirect: 'Visit 1Home for India',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.NO]: {
    name: 'Norway',
    flag: '🇳🇴',
    language: 'Norsk',
    link: '/exclusive-distributor-norway',
    title: 'Visit our official Norway 🇳🇴 website',
    alt: 'Exclusive 1Home distributor in Norway',
    btn_redirect: 'Visit 1Home for Norway',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.NZ]: {
    name: 'New Zealand',
    flag: '🇳🇿',
    language: 'English',
    link: '/exclusive-distributor-new-zealand',
    title: 'Visit our official New Zealand 🇳🇿 website',
    alt: 'Exclusive 1Home distributor in New Zealand',
    btn_redirect: 'Visit 1Home for New Zealand',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.PL]: {
    name: 'Poland',
    flag: '🇵🇱',
    language: 'Polski',
    link: '/exclusive-distributor-poland',
    title: 'Visit our official Poland 🇵🇱 website',
    alt: 'Wyłączny dystrybutor 1Home w Polsce',
    btn_redirect: 'Visit 1Home for Poland',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.PS]: {
    name: 'Palestine',
    flag: '🇵🇸',
    language: 'العربية',
    link: '/exclusive-distributor-palestine',
    title: 'Visit our official Palestine 🇵🇸 website',
    alt: 'الموزع الحصري لمنتجات 1HOME في فلسطين',
    btn_redirect: 'Visit 1Home for Palestine',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  // [ExclusiveCountryCode.TR]: {
  //   name: 'Turkey',
  //   flag: '🇹🇷',
  //   language: 'Türkçe',
  //   link: '/exclusive-distributor-turkey',
  //   title: 'Visit our official Turkey 🇹🇷 website',
  //   alt: '1Home Türkçe Teklif',
  //   btn_redirect: 'Visit 1Home for Turkey',
  //   btn_close: 'Continue to the global site & get redirected on checkout',
  // },
  [ExclusiveCountryCode.UA]: {
    name: 'Ukraine',
    flag: '🇺🇦',
    language: 'Українська',
    link: '/exclusive-distributor-ukraine',
    title: 'Visit our official Ukraine 🇺🇦 website',
    alt: 'Ексклюзивний дистрибютор 1Home в Україні',
    btn_redirect: 'Visit 1Home for Ukraine',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
  [ExclusiveCountryCode.US]: {
    name: 'United States',
    flag: '🇺🇸',
    language: 'English',
    link: '/exclusive-distributor-usa',
    title: 'Visit our official USA 🇺🇸 website',
    alt: 'Exclusive 1Home distributor in the United States',
    btn_redirect: 'Visit 1Home for USA',
    btn_close: 'Continue to the global site & get redirected on checkout',
  },
}

export const translated = {
  [TranslatedCountryCode.DE]: {
    name: 'Deutschland',
    flag: '🇩🇪',
    link: '/de',
    title: 'Besuchen Sie unsere offizielle Website für 🇩🇪 Deutschland',
    alt: 'Exklusiver 1Home Vertriebspartner in Deutschland',
    btn_redirect: 'Zur deutschen 1Home Website',
    btn_close: 'Weiter zur globalen Website & Weiterleitung beim Checkout',
  },
  [TranslatedCountryCode.ES]: {
    name: 'España',
    flag: '🇪🇸',
    link: '/es',
    title: 'Visita nuestra web oficial para 🇪🇸 España',
    alt: 'Distribuidor exclusivo de 1Home en España',
    btn_redirect: 'Visita 1Home para España',
    btn_close: 'Continúe al sitio global y sea redirigido al pagar',
  },
  [TranslatedCountryCode.IT]: {
    name: 'Italia',
    flag: '🇮🇹',
    link: '/it',
    title: 'Visita il nostro sito ufficiale in Italiano 🇮🇹',
    alt: 'Distributore esclusivo di 1Home in Italia',
    btn_redirect: 'Visita 1Home Italia',
    btn_close: 'Continua al sito globale e verrai reindirizzato al checkout',
  },
}

export const exclusiveContact = [
  {
    id: [ExclusiveCountryCode.CY],
    name: 'Cyprus',
    company_name: 'GABS Systems Ltd.',
    address: '65 Atlantos, Lakatamia, Nicosia 2326, Cyprus',
    phone: '+357 99308456',
    email: 'sales@gabs.systems',
    website: 'https://www.gabs.systems',
    link: 'mailto:sales@gabs.systems',
  },
  {
    id: [ExclusiveCountryCode.CZ],
    name: 'Czechia',
    company_name: 'YATUN, s.r.o.',
    address: 'V Olsinach 2300, Praha 100 00',
    phone: '+420 737513890',
    email: 'martin.wokoun@yatun.cz',
    website: 'https://www.yatun.cz',
    link: 'mailto:martin.wokoun@yatun.cz',
  },
  {
    id: [ExclusiveCountryCode.DK],
    name: 'Denmark',
    company_name: 'KNX El & Teknik ApS',
    address: 'Gammel Bagsværdvej 64, 2800 Kongens Lyngby',
    phone: '+45 60897011',
    email: 'sl@knx.dk',
    website: 'https://www.knx.dk',
    link: 'mailto:sl@knx.dk',
  },
  {
    id: [ExclusiveCountryCode.EG],
    name: 'Egypt',
    company_name: 'Domotec Automation',
    address: 'Villa 225, West Golf Katameya, 11936 Cairo',
    phone: '+20 1090011336',
    email: 'sales@domotecautomation.com',
    website: 'https://www.domotecautomation.com',
    link: 'mailto:sales@domotecautomation.com',
  },
  {
    id: [ExclusiveCountryCode.GR],
    name: 'Greece',
    company_name: 'Kafkas',
    address: '',
    phone: '+30 2163003333',
    email: 'customercare@kafkas.gr',
    website: 'https://www.kafkas.gr',
    link: 'mailto:customercare@kafkas.gr',
  },
  // {
  //   id: [ExclusiveCountryCode.HU],
  //   name: 'Hungary',
  //   company_name: 'ZoszkoWorks Kft',
  //   address: '3240 Parád, Kossuth út 56., Hungary',
  //   phone: '+36 305895330',
  //   email: 'info@zoszkoworks.com',
  //   website: 'https://www.zoszkoworks.com',
  //   link: 'mailto:info@zoszkoworks.com',
  // },
  {
    id: [ExclusiveCountryCode.IN],
    name: 'India',
    company_name: 'Morion Automation Pvt. Ltd.',
    address: 'Morion Automation Pvt. Ltd., 4th Floor, Fortune Monarch, Road No 36, Jubliee Hills, Madhapur, Hyderabad',
    phone: '+91 9000600125',
    email: 'info@morion.in',
    website: 'https://www.morion.in',
    link: 'mailto:info@morion.in',
  },
  {
    id: [ExclusiveCountryCode.IL],
    name: 'Israel',
    company_name: 'Inspera Smart Home',
    address: '',
    phone: '+972 0723953007',
    email: 'office@inspera.co.il',
    website: 'https://www.inspera.co.il',
    link: 'mailto:office@inspera.co.il',
  },
  {
    id: [ExclusiveCountryCode.NO],
    name: 'Norway',
    company_name: 'Smarthuset AS',
    address: 'Presidentgata 3C, 0474 Oslo',
    phone: '+47 97979729',
    email: 'post@smarthuset.no',
    website: 'https://www.smarthuset.no',
    link: 'mailto:post@smarthuset.no',
  },
  {
    id: [ExclusiveCountryCode.NZ],
    name: 'New Zealand',
    company_name: 'Knxtra Ltd',
    address: '287 Hoon Hay Road, Christchurch 8025',
    phone: '+64 277274479',
    email: 'jacob@knxtra.co.nz',
    website: 'https://www.knxtra.co.nz',
    link: 'mailto:jacob@knxtra.co.nz',
  },
  {
    id: [ExclusiveCountryCode.PS],
    name: 'Palestine',
    company_name: 'Alfanar',
    address: '',
    phone: '+972 568522031',
    email: 'yasmeen@alfanar.ps',
    website: 'https://www.alfanar.ps',
    link: 'mailto:yasmeen@alfanar.ps',
  },
  {
    id: [ExclusiveCountryCode.PL],
    name: 'Poland',
    company_name: 'DYSTRON',
    address: 'Hetmańska 26, 60-252 Poznań, Poljska',
    phone: '+48 515545946',
    email: 'zamowienia@dystron.pl',
    website: 'https://www.dystron.pl',
    link: 'mailto:zamowienia@dystron.p',
  },
  // {
  //   id: [ExclusiveCountryCode.TR],
  //   name: 'Turkey',
  //   company_name: '',
  //   address: '',
  //   phone: '',
  //   email: '',
  //   website: '',
  //   link: '',
  // },
  {
    id: [ExclusiveCountryCode.UA],
    name: 'Ukraine',
    company_name: 'Smart Tech',
    address: '28A Panasa Myrnoho Str. Kyiv, Ukraine',
    phone: '+380 445457706',
    email: 'sales@smarttech.com.ua',
    website: 'https://www.smarttech.com.ua',
    link: 'mailto:sales@smarttech.com.ua',
  },
  {
    id: [ExclusiveCountryCode.US],
    name: 'United States',
    company_name: 'KNX Supply',
    address: 'Wolf Building Technologies LLC, 7950 NW 53rd Street, Suite 337, Miami, Florida 33166',
    phone: '+1 786 956 6821',
    email: 'support@knxsupply.com',
    website: 'https://www.knxsupply.com',
    link: 'mailto:support@knxsupply.com',
  },
]
